export default {
  // data () {
  //   return {
  //     windowSize: null,
  //     windowScrollY: null
  //   }
  // },
  // methods: {
  //   handleWindowSize () {
  //     this.windowSize = window.innerWidth
  //   },
  //   handleWindowScrollY () {
  //     this.windowScrollY = window.scrollY
  //   }b
  // },
  // mounted () {
  //   this.handleWindowSize()
  //   this.handleWindowScrollY()
  //   window.addEventListener('resize', this.handleWindowSize)
  //   window.addEventListener('scroll', this.handleWindowScrollY)
  // },
  // beforeDestroy () {
  //   window.removeEventListener('resize', this.handleWindowSize)
  //   window.removeEventListener('scroll', this.handleWindowScrollY)
  // }
}
